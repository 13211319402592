export default async function makeRequest(url, body = null, method = 'POST') {
    const requestOptions = {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: body ? JSON.stringify(body) : null
    };

    try {
        const response = await fetch('https://fox-api.pp.ua' + url, requestOptions);
        console.log(response)
        return response;
    } catch (error) {
        console.error('Request failed', error);
      //  throw new Error('Request failed');
    }
}

// https://scabra-api.pp.ua/