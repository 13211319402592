import React from "react";
import {Link} from "react-router-dom";
import instagram from "./instagram.svg"
import linked from "./linkedin.svg"
import telegram from "./telegram.svg"
import github from "./github.svg"
import "./social-list.scss"

export default function SocialList({modifier, theme}) {

    return <div className={modifier ? `social-list social-list--${modifier}` : "social-list"}>

        <Link to={'https://github.com/Cunning-Fox-99'} className="social-list__item">
            <svg className={theme === 'dark' ? "social-list__item social-list__item--dark" :"social-list__item"}>
                <use xlinkHref={github + "#github"} />
            </svg>
        </Link>

        <Link to={'https://www.linkedin.com/in/fox-dev/'} className="social-list__item">
            <svg className={theme === 'dark' ? "social-list__item social-list__item--dark" :"social-list__item"}>
                <use xlinkHref={linked + "#linked"} />
            </svg>
        </Link>

        <Link to={'https://t.me/Fox_Dev_99'} className="social-list__item">
            <svg className={theme === 'dark' ? "social-list__item social-list__item--dark" :"social-list__item"}>
                <use xlinkHref={telegram + "#telegram"} />
            </svg>
        </Link>

        <Link to={'https://www.instagram.com/fox.f.dev/'} className="social-list__item">
            <svg className={theme === 'dark' ? "social-list__item social-list__item--dark" :"social-list__item"}>
                <use xlinkHref={instagram + "#instagram"} />
            </svg>
        </Link>


    </div>
}