
import './footer.scss'
import SocialList from "../social-list/social-list";
export default function Footer({theme}) {

    return <div className={theme === 'dark' ? "footer footer--dark" : "footer"}>
        <SocialList theme={theme}/>
        <div className="footer__copyright">
            © 2024  -  Kostyantyn Morozovskiy
        </div>
    </div>
}