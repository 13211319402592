import {Link} from "react-router-dom";
import './header.scss'
import { ReactComponent as DarkIcon } from './dark.svg';
import { ReactComponent as LightIcon } from './sun.svg';
import { ReactComponent as LogoIcon } from './logo.svg';
import {useEffect, useState} from "react";
import gsap from 'gsap';
export default function Header({getTheme}) {

    const [active, setActive] = useState(false)
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('dark') ? true : false);

    const onClickHandler = (id) => {
        const elem = document.querySelector('#' + id);
        if (elem)
            elem.scrollIntoView({ behavior: 'smooth' });
    }

    const toggleTheme = () => {
        setIsDarkMode(prevState => {
            if (prevState) {
                getTheme('light')
                localStorage.removeItem('dark')
            } else {
                getTheme('dark')
                localStorage.setItem('dark', 'true')
            }

            return !prevState
        });
    };

    useEffect(() => {
        gsap.to('.theme-icon', { rotation: 360, transformOrigin: 'center', duration: 0.5 });
    }, [isDarkMode]);

    return <div className={isDarkMode ? "header header--dark container" : "header container"}>
        <Link to='/' className="header__logo">
            <div className="header__logo-container">
                <LogoIcon />
                <div className="header__logo-text">
                    <span>Kostyantyn</span>
                    <span>Morozovskiy</span>
                </div>
            </div>
        </Link>

        <ul className={active ? "header__nav active" : "header__nav"}>
            <li className="header__nav-item" onClick={() => setActive(false)}>
                <Link to='#services' onClick={() => onClickHandler('services')}>Services</Link>
            </li>

            <li className="header__nav-item" onClick={() => setActive(false)}>
                <Link to='#works'  onClick={() => onClickHandler('portfolio')}>Portfolio</Link>
            </li>

            <li className="header__nav-item" onClick={() => setActive(false)}>
                <Link to='#about-me'  onClick={() => onClickHandler('about-me')}>Experience</Link>
            </li>

            <li className="header__nav-item" onClick={() => setActive(false)}>
                <Link to='#contacts'  onClick={() => onClickHandler('contacts')}>Contact</Link>
            </li>

            <li className="header__nav-item header__nav-item--resume" onClick={() => setActive(false)}>
                <Link to='https://storage.fox-dev.pp.ua/cv.pdf'>Resume</Link>
            </li>

        </ul>

        <div className="header__buttons">
            <div className="header__mode" onClick={toggleTheme}>
                {isDarkMode ? <LightIcon className="theme-icon"/> : <DarkIcon className="theme-icon"/>}
            </div>

            <Link to='https://storage.fox-dev.pp.ua/cv.pdf' className="header__resume">Resume</Link>

            <div onClick={() => setActive(prevState => !prevState)} className={active ? "header__mobile-button active" : "header__mobile-button"}>
                <div className="header__mobile-button-button"></div>
            </div>
        </div>
    </div>
}