import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";

export default function Layout({getTheme, theme, children}) {

    return (
        <>
            <Header getTheme={(theme) => getTheme(theme)}/>
            {children}
            <Footer theme={theme}/>
        </>
    );
}