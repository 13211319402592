import './education.scss'

export default function Education() {

    return <div id="education" className="education container">

        <div className="education__top">
            <span className="education__subtitle subtitle">LEARNING PATH</span>
            <h2 className="education__title">Education & Skills</h2>
        </div>

        <div className="education__wrapper">

            <div className="education__column">

                <div className="education__item">
                    <h5 className="education__item-title">OTK ONAFT</h5>
                    <span className="education__item-description">Junior Specialist - Software Engineering</span>
                    <span className="education__item-time">2015-2019</span>
                </div>

                <div className="education__item">
                    <h5 className="education__item-title">ONAFT</h5>
                    <span className="education__item-description">Bachelor - Software Engineering</span>
                    <span className="education__item-time">2019-2021</span>
                </div>

                <div className="education__item">
                    <h5 className="education__item-title">ONAFT</h5>
                    <span className="education__item-description">Master's degree - Software Engineering</span>
                    <span className="education__item-time">2021-2022</span>
                </div>

            </div>

            <div className="education__column">

                <div className="education__text">I gained my skills in practice by working on commercial projects. Initially, I only knew layout, then I added JavaScript and then React. At the moment I am learning NodeJS and MongoDB, but I can already create BackEnd applications that I will later use on the site.
                </div>

                <div className="education__skills">

                    <div className="education__skill">
                        <span>HTML, CSS, SCSS, LESS, SASS, JavaScript, RestAPI</span>
                        <div className="education__skill-progress education__skill-progress--90"></div>
                    </div>

                    <div className="education__skill">
                        <span>React, Redux, React Router</span>
                        <div className="education__skill-progress education__skill-progress--80"></div>
                    </div>

                    <div className="education__skill">
                        <span>NodeJS, MongoDB, Express</span>
                        <div className="education__skill-progress education__skill-progress--50"></div>
                    </div>

                </div>

            </div>

        </div>

    </div>
}