import {Link} from "react-router-dom";
import pin from './pin.svg'
import phone from './phone.svg'
import mail from './mail.svg'
import './contact-form.scss'
import {useState} from "react";
import makeRequest from "../../request";

export default function ContactForm() {

    const pass = '!;8X;ZaVs6y@cLzA7u9c)-E4)S53f7R%'
    const [subject, setSubject] = useState('')
    const [email, setEmail] = useState('')
    const [text, setText] = useState('')

    const formSubmit = async (e) => {
        e.preventDefault()
        console.log(subject)
        console.log(email)
        console.log(text)

        const data = {
            subject: subject,
            email: 'kostya.moroz.96@gmail.com',
            message: email + '\n' + text,
            pass
        }

        console.log(data)
        // https://fox-api.pp.ua/api/send-email
        makeRequest('/api/send-email', data).then(r => {
            console.log(r)
            if (r.status === 200) {
                alert('Message sent')
                setSubject('')
                setEmail('')
                setText('')
            }
        })
    }

    return <div id="contacts" className="contact-form container">

        <div className="contact-form__column">
            <form onSubmit={formSubmit} className="contact-form__form">

                <div className="contact-form__form-wrapper">

                    <label className="contact-form__form-input">
                        <input value={subject} onChange={e => setSubject(e.target.value)} required={true} type="text" placeholder="Subject"/>
                    </label>

                    <label className="contact-form__form-input">
                        <input value={email} onChange={e => setEmail(e.target.value)} required={true} type="text" placeholder="Email"/>
                    </label>

                    <label className="contact-form__form-input">
                        <textarea value={text} onChange={e => setText(e.target.value)} cols="30" rows="10" placeholder="Message"/>
                    </label>

                </div>

                <button type='submit' className="contact-form__form-button">Send Message</button>

            </form>
        </div>

        <div className="contact-form__column">

            <div className="contact-form__item">
                <div className="contact-form__item-image">
                    <img src={pin} alt=""/>
                </div>

                <div className="contact-form__item-wrapper">
                    <h4>Address</h4>
                    <span>Ukraine, Odessa</span>
                </div>
            </div>

            <div className="contact-form__item">
                <div className="contact-form__item-image">
                    <img src={phone} alt=""/>
                </div>

                <div className="contact-form__item-wrapper">
                    <h4>Phone</h4>
                    <Link to='tel:+380994632900'>+38 (099) 463 29 00</Link>
                </div>
            </div>

            <div className="contact-form__item">
                <div className="contact-form__item-image">
                    <img src={mail} alt=""/>
                </div>

                <div className="contact-form__item-wrapper">
                    <h4>E-Mail</h4>
                    <Link to='mailto:kostya.moroz.96@gmail.com'>kostya.moroz.96@gmail.com</Link>
                </div>
            </div>

        </div>

    </div>
}