export default function ServicesCard({card}) {

    return <div className="services__card">

        <div className="services__card-image">
            <img src={card.image} alt=""/>
        </div>

        <h5 className="services__card-title">{card.title}</h5>
        <span className="services__card-text">{card.text}</span>
    </div>
}