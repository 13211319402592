import {Link, useNavigate} from "react-router-dom";
import linkSvg from './link.svg'

export default function PortfolioCard({card}) {

    const navigate = useNavigate()

    const handleLinkClick = (externalUrl) => {
        window.location.href = externalUrl; // Перенаправление на внешний URL
    };

    return <div className="portfolio__card" onClick={() => handleLinkClick(card.link)}>
        <Link to={card.link} className="portfolio__card-image" >
            <img src={card.image} alt=""/>
        </Link>

        <div className="portfolio__card-body">
            <h5 className="portfolio__card-title">{card.title}</h5>
            <div className="portfolio__card-bottom">
                <span className="portfolio__card-text">{card.text}</span>
                <Link to={card.link}><img src={linkSvg} alt=""/></Link>
            </div>

        </div>

    </div>
}