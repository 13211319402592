import Layout from "./components/layout/layout";
import MainBlock from "./components/main-block/main-block";
import Services from "./components/services/services";
import Portfolio from "./components/portfolio/portfolio";
import Work from "./components/work/work";
import Education from "./components/education/education";
import ContactForm from "./components/contact-form/contact-form";
import {useEffect, useState} from "react";

function App() {

    const [theme, setTheme] = useState('light')

    useEffect(() => {
        if (localStorage.getItem('dark')) {
            setTheme('dark')
            document.body.classList.add('dark')
        } else {
            setTheme('light')
            document.body.classList.remove('dark')
        }
    }, [theme]);

    return (
        <Layout theme={theme} getTheme={(theme) => setTheme(theme)}>
             <MainBlock theme={theme}/>

            <div className="grey">
                <Services theme={theme}/>
            </div>

            <Portfolio theme={theme}/>

            <div className="grey">
                <Work />
            </div>

            <Education />

            <div className="grey">
                <ContactForm />
            </div>

        </Layout>
    );
}

export default App;
