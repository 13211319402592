import {useState} from "react";
import './work.scss'

export default function Work() {

    const [active, setActive] = useState(1)

    return <div id="about-me" className="work container">

        <div className="work__column">
            <span className="work__subtitle subtitle">career path</span>
            <h2 className="work__title">Work Experience</h2>

            <div className="work__nav">
                <div onClick={() => setActive(1)} className={active === 1 ? "work__nav-item active" : "work__nav-item"}><span>Small team</span><span>&#10148;</span></div>
            </div>
        </div>

        <div className="work__column">

            {active === 1 &&<div className="work__item">
                <h5 className="work__item-title">Front-end developer</h5>
                <span className="work__place">Ukraine, Odessa</span>
                <span className="work__time">March 2021 - Present</span>

                <ul className="work__item-list">
                    <li className="work__item-list-item">Creating websites using Figma layout</li>
                    <li className="work__item-list-item">Linking the API to the site</li>
                    <li className="work__item-list-item">Website development using React</li>
                    <li className="work__item-list-item">Writing BackEnd in NodeJS</li>
                </ul>
            </div>}
        </div>

    </div>
}