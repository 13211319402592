import ServicesCard from "./services-card";
import './services.scss'
import card1 from './card-1.svg'
import card2 from './card-2.svg'
import card3 from './card-3.svg'

export default function Services({theme}) {

    const arr = [
        {image: card1, title: 'Full-stack', text: 'Development of websites with database, storage and user interaction.'},
        {image: card2, title: 'Front-end', text: 'I can create a website of any complexity with animations based on a layout from Figma'},
        {image: card3, title: 'Back-end', text: 'If you need a database for your website, you can create it using NodeJS and MongoDB'}
    ]

    return <div id="services" className={theme === 'dark' ? "services services--dark container" : "services container"}>

        <span className="services__subtitle subtitle">SERVICES</span>
        <h2 className="services__title">Specialized in</h2>

        <div className="services__wrapper">
            {arr.map((card, index) => {
                return <ServicesCard card={card} key={index}/>
            })}
        </div>

    </div>
}