import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import {Autoplay, FreeMode, Pagination} from "swiper/modules";
import PortfolioCard from "./portfolio-card";
import './portfolio.scss'
import shoppe from './shoppe.jpg'
import fantransport from './fan-transport.jpg'
import healthclean from './healthclean.jpg'
import cleanstorm from './clean-storm.jpg'
import creative from './creative.jpg'
import erfolg from './erfolg.jpg'
import scabra from './scabra.jpg'

export default function Portfolio({theme}) {

    const arr = [
        {image: shoppe, title: 'Shoppe', text: 'react', link: 'https://shoppe.fox-dev.pp.ua/'},
        {image: scabra, title: 'Public + Admin panel', text: 'MERN', link: 'https://scabra.fox-dev.pp.ua/'},
        {image: fantransport, title: 'Fan transport', text: 'react', link: 'https://f.fox-dev.pp.ua/'},
        {image: healthclean, title: 'Health-clean', text: 'MERN', link: 'https://health-clean.fox-dev.pp.ua/'},
        {image: cleanstorm, title: 'Clean storm', text: 'MERN', link: 'https://clean-storm.fox-dev.pp.ua/'},
        {image: creative, title: 'Creative clout', text: 'HTML&CSS', link: 'https://fox-creative.fox-dev.pp.ua/'},
        {image: erfolg, title: 'Erfolg', text: 'React', link: 'https://erfolg.fox-dev.pp.ua/'},

    ]

    return <div id="portfolio" className={theme === 'dark' ? "portfolio portfolio--dark container" : "portfolio container"}>

        <div className="portfolio__top">
            <span className="portfolio__subtitle subtitle">MY WORKS</span>
            <h2 className="portfolio__title">Featured Portfolios</h2>
        </div>

            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                pagination={{
                    enabled: false
                }}
                modules={[FreeMode, Pagination, Autoplay]}
                breakpoints={{
                    620: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    860: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    }
                }}
                className="mySwiper"
            >
                {arr.map((card, index) => {
                    return <SwiperSlide key={index}>
                        <PortfolioCard card={card} key={index} />
                    </SwiperSlide>
                })}
            </Swiper>
    </div>
}