import React from "react";
import './main-block.scss'
import SocialList from "../social-list/social-list";
import image from './image.png'
export default function MainBlock({theme}) {

    return <div className="main-block container">

        <div className="main-block__column">
            <span className="main-block__subtitle subtitle">MY NAME IS</span>
            <h1 className="main-block__title">Kostyantyn <span>Morozovskiy</span></h1>
            <p className="main-block__text">Skilled front-end developer with more than 3 years of experience in commercial projects. Knowledge of JavaScript and React. I'm following the MERN stack.</p>
            <SocialList modifier='dark' theme={theme}/>
        </div>

        <div className="main-block__column">
            <img src={image} alt=""/>
        </div>

    </div>
}